import Graphic from '@arcgis/core/Graphic';
import Layer from '@arcgis/core/layers/Layer';
import SceneLayerView from '@arcgis/core/views/layers/SceneLayerView';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { RootState } from './index';
import { clearPresentation } from './presentationSlice';

interface PolygonsLayerSliceState {
    layer?: Layer;
    sceneLayerView?: SceneLayerView;
    graphicsToUpdate: Graphic[];
    selectedGraphics: Graphic[];
    selectedLayerId?: string;
    openPolygonEditor?: boolean;
    editedMarketSpherePropertyId: number[];
    editedBlackbirdIds: number[];
}

const initialState: PolygonsLayerSliceState = {
    graphicsToUpdate: [] as Graphic[],
    selectedGraphics: [] as Graphic[],
    editedMarketSpherePropertyId: [],
    editedBlackbirdIds: [],
};

export const polygonsLayerSlice = createSlice({
    name: 'polygonsLayer',
    initialState,
    reducers: {
        addGraphicsToUpdate(state, action: PayloadAction<Graphic[]>) {
            state.graphicsToUpdate = action.payload;
        },
        setSelectedLayerId(state, action: PayloadAction<string | undefined>) {
            state.selectedLayerId = action.payload;
        },
        setSelectedGraphics(state, action: PayloadAction<Graphic[]>) {
            state.selectedGraphics = action.payload;
        },
        cleanSelectedGraphics(state) {
            state.selectedGraphics = [];
        },
        setOpenPolygonEditor(state, action: PayloadAction<boolean>) {
            state.openPolygonEditor = action.payload;
        },
        addEditedMarketSpherePropertyIds(state, action: PayloadAction<number[]>) {
            const marketSpherePropertyIds = [
                ...state.editedMarketSpherePropertyId,
                ...action.payload,
            ];
            state.editedMarketSpherePropertyId = uniq(marketSpherePropertyIds);
        },
        addEditedBlackbirdIds(state, action: PayloadAction<number[]>) {
            const bbIds = [...state.editedBlackbirdIds, ...action.payload];
            state.editedBlackbirdIds = uniq(bbIds);
        },
        removeEditedMarketSpherePropertyIds(state, action: PayloadAction<number[]>) {
            state.editedMarketSpherePropertyId = state.editedMarketSpherePropertyId.filter(
                (id) => !action.payload.includes(id)
            );
        },
        removeEditedBlackbirdPropertyIds(state, action: PayloadAction<number[]>) {
            state.editedBlackbirdIds = state.editedBlackbirdIds.filter(
                (id) => !action.payload.includes(id)
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const {
    addGraphicsToUpdate,
    setSelectedGraphics,
    setSelectedLayerId,
    cleanSelectedGraphics,
    setOpenPolygonEditor,
    addEditedMarketSpherePropertyIds,
    addEditedBlackbirdIds,
    removeEditedMarketSpherePropertyIds,
    removeEditedBlackbirdPropertyIds,
} = polygonsLayerSlice.actions;

export const selectGraphicsToUpdate = (state: RootState): Graphic[] => {
    return state.polygonsLayer?.graphicsToUpdate;
};

export const selectedGraphics = (state: RootState): Graphic[] => {
    return state.polygonsLayer?.selectedGraphics;
};

export const selectedGraphicLayerId = (state: RootState): string | undefined => {
    return state.polygonsLayer?.selectedLayerId;
};

export const selectOpenPolygonEditor = (state: RootState): boolean | undefined => {
    return state.polygonsLayer?.openPolygonEditor;
};

export const selectEditedMarketSpherePropertyId = (state: RootState): number[] => {
    return state.polygonsLayer?.editedMarketSpherePropertyId;
};

export const selectEditedBlackbirdIds = (state: RootState): number[] => {
    return state.polygonsLayer?.editedBlackbirdIds;
};

export default polygonsLayerSlice.reducer;
