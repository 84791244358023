import { createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';

import { FocusedSlide, Slide, SlideItem } from 'components/Slides/SlideTypes';
import { SlideFolder } from 'components/Slides/SlideTypes';
import { RootState } from 'store';
import { GoToTarget3DOptions } from 'types/GoToTarget3DOptions';
import * as slideUtils from 'utils/slideUtils';
import { createSlideIterator } from 'utils/slideUtils';
import { clearPresentation, fetchPresentation } from './presentationSlice';
import { activateSlide } from './useSlideActions';

interface SlideSliceState {
    slides: SlideItem[];
    focusedSlide?: FocusedSlide;
    showSlideName: boolean;
}

const initialState: SlideSliceState = {
    slides: [] as SlideItem[],
    showSlideName: true,
};

export const loadSlideOnPresentationOpen = (
    slides: SlideItem[],
    setGoTo: (options: GoToTarget3DOptions) => void,
    slideParam: string | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
): ThunkAction<void, any, unknown, any> => {
    return (dispatch) => {
        const flattenedSlides = [...createSlideIterator(slides)];
        if (flattenedSlides.length === 0) {
            // If there are no slides, skip trying to activate one
            return;
        }

        let slideNumber = slideParam != null ? parseInt(slideParam) : null;
        if (
            slideNumber == null ||
            !Number.isFinite(slideNumber) ||
            slideNumber < 1 ||
            slideNumber > flattenedSlides.length
        ) {
            // Default to slide 1 if an invalid slide is given or no slide was specified
            slideNumber = 1;
        }

        const slide = flattenedSlides[slideNumber - 1];
        dispatch(activateSlide(slide, setGoTo));
    };
};

export const slideSlice = createSlice({
    name: 'slide',
    initialState,
    reducers: {
        addSlide: (state, { payload }: { payload: SlideItem }) => {
            state.slides.push(payload);
        },
        setSlides: (state, { payload }: { payload: SlideItem[] }) => {
            state.slides = payload;
        },
        setFocusedSlide: (state, { payload }: { payload: FocusedSlide | undefined }) => {
            state.focusedSlide = payload;
        },
        addSlideFolder: (state, { payload }: { payload: SlideFolder }) => {
            state.slides.push(payload);
        },
        setActiveSlide: (state, { payload }: { payload: Slide }) => {
            for (const slide of createSlideIterator(state.slides)) {
                slide.active = slide.key === payload.key;
            }
        },
        updateSlide: (
            state,
            { payload: { key, updates } }: PayloadAction<{ key: string; updates: Partial<Slide> }>
        ) => {
            const { slide } = slideUtils.findSlide(state.slides, (item) => item.key === key);
            if (slide) {
                Object.assign(slide, updates);
            }
        },
        setShowSlideName: (state, action) => {
            state.showSlideName = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);

        builder
            .addCase(fetchPresentation.pending, () => initialState)
            .addCase(fetchPresentation.fulfilled, (state, { payload: { slides } }) => {
                state.slides = slides;
            });
    },
});

export const {
    addSlide,
    setSlides,
    addSlideFolder,
    updateSlide,
    setFocusedSlide,
    setActiveSlide,
    setShowSlideName,
} = slideSlice.actions;

export const selectSlides = (state: RootState): SlideItem[] => {
    return state.slide.slides;
};

export const selectFocusedSlide = (state: RootState): FocusedSlide | undefined => {
    return state.slide.focusedSlide;
};

export const selectActiveSlideName = (state: RootState): string | undefined => {
    const findActiveSlide = (slides: SlideItem[]) =>
        slides.find(
            (slide: SlideItem) =>
                (slide?.slideItemType === 'Slide' && slide.active) ||
                (slide?.slideItemType === 'Folder' && slide.children.some((child) => child.active))
        );

    const activeSlide = findActiveSlide(state.slide.slides);
    if (!activeSlide) return undefined;

    if (activeSlide.slideItemType === 'Slide') {
        return activeSlide.name;
    }

    const activeChildSlide = findActiveSlide(activeSlide.children);
    return activeChildSlide?.name;
};

export const selectShowSlideName = (state: RootState): boolean => {
    return state.slide.showSlideName;
};

export default slideSlice.reducer;
