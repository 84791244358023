import { createListenerMiddleware, Dispatch, TypedStartListening } from '@reduxjs/toolkit';

import { QuickActionType } from 'components/Chat/quickActionType';
import { RootState } from 'store';
import {
    removeCleanupAction,
    selectAssistantVisible,
    selectCleanupActions,
} from './assistantSlice';
import { setDefaultValues, setShowResults } from './searchSlice';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as TypedStartListening<RootState>;

const cleanupActionsByType: Partial<Record<QuickActionType, (dispatch: Dispatch) => void>> = {
    [QuickActionType.Search]: (dispatch) => {
        dispatch(setShowResults(false));
        dispatch(setDefaultValues({}));
        dispatch(removeCleanupAction(QuickActionType.Search));
    },
};

startAppListening({
    predicate: (_, currentState, previousState) => {
        return selectAssistantVisible(currentState) !== selectAssistantVisible(previousState);
    },
    effect: async (_, listenerApi) => {
        const assistantVisible = selectAssistantVisible(listenerApi.getState());
        if (!assistantVisible) {
            const cleanupActions = selectCleanupActions(listenerApi.getState());
            Object.keys(cleanupActions).forEach((actionType) => {
                const cleanup = cleanupActionsByType[actionType as QuickActionType];
                if (cleanup) {
                    cleanup(listenerApi.dispatch);
                }
            });
        }
    },
});

export const assistantCleanupListenerMiddleware = listenerMiddleware.middleware;
