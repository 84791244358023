import bankIconUrl from 'icons/amenities/bank.svg?url';
import barIconUrl from 'icons/amenities/bar.svg?url';
import cafeIconUrl from 'icons/amenities/cafe.svg?url';
import chargingStationIconUrl from 'icons/amenities/charging-station.svg?url';
import doctorsIconUrl from 'icons/amenities/doctors.svg?url';
import dryCleaningIconUrl from 'icons/amenities/dry-cleaning.svg?url';
import fastFoodIconUrl from 'icons/amenities/fast-food.svg?url';
import groceryIconUrl from 'icons/amenities/grocery-store.svg?url';
import hardwareIconUrl from 'icons/amenities/home-improvement.svg?url';
import hospitalIconUrl from 'icons/amenities/hospital.svg?url';
import parkingIconUrl from 'icons/amenities/parking.svg?url';
import pharmacyIconUrl from 'icons/amenities/pharmacy.svg?url';
import postOfficeIconUrl from 'icons/amenities/post-office.svg?url';
import restaurantIconUrl from 'icons/amenities/restaurant.svg?url';
import schoolIconUrl from 'icons/amenities/school.svg?url';

export const AMENITIES_GROUPS_CONFIG = [
    {
        key: 'food',
        title: 'Food',
        children: [
            {
                key: 'bar',
                title: 'Bar',
                icon: barIconUrl,
                categories: [
                    'bar',
                    'bar;restaurant;pub',
                    'bar;restaurent',
                    'barber_school',
                    'barbershop',
                    'barometer',
                    'barrel',
                    'cafe;bar',
                    'cafe;juice_bar',
                    'cigar_bar',
                    'hookah_bar',
                    'juice_bar',
                    'pub;bar;restaurant',
                    'restaurant;bar',
                    'restaurant;bar;',
                    'snack_bar',
                    'winebar',
                ],
            },
            {
                key: 'cafe',
                title: 'Cafe',
                icon: cafeIconUrl,
                categories: [
                    'arts_centre;cafe',
                    'boat_rental;cafe',
                    'cafe',
                    'cafe;bakery',
                    'cafe;bar',
                    'cafe;fast_food',
                    'cafe;gas',
                    'cafe;ice_cream',
                    'cafe;internet_cafe',
                    'cafe;juice_bar',
                    'cafe;restaurant',
                    'disused:cafe',
                    'fast_food;cafe',
                    'fire_station;restaurant;cafe',
                    'ice_cream;cafe',
                    'internet_cafe',
                    'music_venue;cafe',
                    'restaurant;cafe',
                ],
            },
            {
                key: 'fast_food',
                title: 'Fast Food',
                icon: fastFoodIconUrl,
                categories: [
                    'bakery; fast_food',
                    'cafe;fast_food',
                    'fast food',
                    'Fast Forward Training',
                    'fast_food',
                    'fast_food;cafe',
                    'fast_food;ice_cream',
                    'fast_food;library',
                    'fuel;fast_food',
                    'restaurant;fast_food',
                ],
            },
            {
                key: 'restaurant',
                title: 'Restaurant',
                icon: restaurantIconUrl,
                categories: ['restaurant', 'bar;restaurent', 'bbq'],
            },
        ],
    },
    {
        key: 'medical',
        title: 'Medical',
        children: [
            {
                key: 'pharmacy',
                title: 'Pharmacy',
                icon: pharmacyIconUrl,
                categories: ['pharmacy'],
            },
            {
                key: 'hospital',
                title: 'Hospital',
                icon: hospitalIconUrl,
                categories: ['hospital;clinic', 'hospital'],
            },
            {
                key: 'doctors',
                title: 'Doctors',
                icon: doctorsIconUrl,
                categories: ['doctors', 'hospital'],
            },
        ],
    },
    {
        key: 'vehicle',
        title: 'Vehicle',
        children: [
            {
                key: 'charging_station',
                title: 'Charging Station',
                icon: chargingStationIconUrl,
                categories: [
                    'charging_station',
                    'device_charging_station',
                    'wifi;telephone;device_charging_station',
                ],
            },
            {
                key: 'parking',
                title: 'Parking',
                icon: parkingIconUrl,
                categories: [
                    'bench;bicycle_parking',
                    'bicycle_parking',
                    'bicycle_parking;bank',
                    'bicycle_parking;bicycle_rental',
                    'bicycle_parking;townhall',
                    'bus_parking',
                    'dog_parking',
                    'horse_parking',
                    'kick-scooter_parking',
                    'moped parking',
                    'moped_parking',
                    'motorcycle_parking',
                    'old_parking',
                    'parking',
                    'parking lot',
                    'parking;phone',
                    'parking;toilets',
                    'parking_booth',
                    'parking_entrance',
                    'parking_exit',
                    'parking_meter',
                    'parking_section',
                    'parking_sign',
                    'parking_space',
                    'rv parking',
                    'school;parking',
                    'scooter_parking',
                    'shelter;bicycle_parking;bicycle_rental',
                    'skateboard_parking',
                    'ski_parking',
                    'stroller_parking',
                    'valet_parking',
                    'wheelchair_parking',
                ],
            },
        ],
    },
    {
        key: 'errands',
        title: 'Errands',
        children: [
            {
                key: 'bank',
                title: 'Bank',
                icon: bankIconUrl,
                categories: [
                    'atm;bank',
                    'bank',
                    'bank;taxi',
                    'bank_atm',
                    'bicycle_parking;bank',
                    'food_bank',
                ],
            },
            {
                key: 'dry_cleaning',
                title: 'Dry Cleaning',
                icon: dryCleaningIconUrl,
                categories: ['dry_cleaner', 'laundry'],
            },
            {
                key: 'grocery_stores',
                title: 'Grocery Stores',
                icon: groceryIconUrl,
                categories: ['grocery'],
            },
            {
                key: 'hardware_store',
                title: 'Home Improvement',
                icon: hardwareIconUrl,
                categories: [
                    'hardware_store',
                    'flooring Store',
                    'appliance store',
                    'Appliance Shops',
                ],
            },
            {
                key: 'post_office',
                title: 'Post Office',
                icon: postOfficeIconUrl,
                categories: [
                    'health_post',
                    'hitching_post',
                    'lamp_post',
                    'mile_post',
                    'money_transfer; post_office',
                    'murmur_listening_post',
                    'post_box',
                    'post_box;post_office',
                    'post_box;recycling',
                    'post_box;telephone',
                    'post_depot',
                    'post_depot;post_office',
                    'post_office',
                    'post_storage_box',
                    'postal_relay_box',
                    'postal_service',
                    'poster_board',
                    'relay_post_box',
                    'signpost',
                ],
            },
        ],
    },
    {
        key: 'schools',
        title: 'Schools',
        children: [
            {
                key: 'universities',
                title: 'Universities',
                icon: schoolIconUrl,
                categories: ['university', 'university_building', 'arts_centre;university'],
            },
            {
                key: 'schools',
                title: 'Schools',
                icon: schoolIconUrl,
                categories: [
                    'high school',
                    'grade_school',
                    'school',
                    'pre_school',
                    'school;kindergarten',
                ],
            },
        ],
    },
];
