import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import SceneView from '@arcgis/core/views/SceneView';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import { getBuildingIdentityLayer } from 'components/map/controllers/BuildingIdentityController';
import { fetchFeaturesFromLayerView } from 'helpers/buildingIdentityHelper';
import { RootState } from 'store';
import { BuildingIdentitySliceState } from 'types/buildingIdentity';
import isDefined from 'utils/isDefined';

/* 

 initial state

*/

const initialState: BuildingIdentitySliceState = {
    visibleIdentities: {},
    accumulatedIdentities: {},
    status: 'idle',
};

/* 

 reducers

*/

const buildingIdentitySlice = createSlice({
    name: 'buildingIdentity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //
        // fetchBuildingIdentities
        //
        builder
            .addCase(fetchBuildingIdentities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBuildingIdentities.fulfilled, (state, action) => {
                state.status = 'ready';

                const newVisibleBis = keyBy(action.payload, 'buildingId');

                // replace visible bis
                state.visibleIdentities = newVisibleBis;

                // adds new entries, and overwrites if already there
                action.payload.forEach((bi) => {
                    state.accumulatedIdentities[bi.buildingId] = bi;
                });
            })
            .addCase(fetchBuildingIdentities.rejected, (state, action) => {
                state.status = 'failed';
                console.error('Error fetching Building Identities', action.error.message);
            });
    },
});

/* 

 selectors

 */

export const selectBldgIdentities = (state: RootState) => state.buildingIdentity.visibleIdentities;

export const selectBldgIdentitiesArray = createSelector([selectBldgIdentities], (bis) =>
    Object.values(bis)
);

export const selectBldgIdentitiesIds = createSelector([selectBldgIdentities], (bis) =>
    Object.keys(bis)
);

export const selectBldgIdentitiesWithOSM = createSelector(selectBldgIdentitiesArray, (identities) =>
    identities.filter((bi) => bi.representation?.type === 'osm')
);
export const selectRepresentationsTypeOSM = createSelector(
    selectBldgIdentitiesWithOSM,
    (identities) => identities.map((i) => i.representation).filter(isDefined)
);

export const selectRepresentationIdsTypeOSM = createSelector(selectRepresentationsTypeOSM, (reps) =>
    reps.map((r) => r.id)
);

/* 

  async fetch functions 

 */

export const fetchBuildingIdentities = createAsyncThunk(
    'buildingIdenty/fetchIdentities',
    async ({ view }: { view: SceneView }) => {
        const { layerView } = await getLayerAndLayerView(view);
        if (!layerView) return [];

        return await fetchFeaturesFromLayerView(layerView);
    }
);

// export slice reducer
export default buildingIdentitySlice.reducer;

/*


 internal helper methods


*/

async function getLayerAndLayerView(view: SceneView): Promise<{
    layer?: FeatureLayer;
    layerView?: FeatureLayerView;
}> {
    const layer = getBuildingIdentityLayer();
    if (!layer) return {};
    const layerView = await view.whenLayerView(layer);
    return { layer, layerView };
}

export const _for_testing_only_ = {
    initialState: initialState,
};
