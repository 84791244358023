import { groupBy } from 'lodash';

export interface MarketSearch {
    market: string;
    submarket: string;
}

export const createMarketQuery = (marketNames: Array<string>, propertyTypes: Array<string>) => {
    let marketQuery = '';
    if (marketNames?.length) {
        marketNames.forEach(function (item, index) {
            marketQuery = marketQuery + `LOWER(MarketName) like '%${item.toLowerCase()}%'`;
            if (index < marketNames.length - 1) marketQuery = marketQuery + ' OR ';
        });
    }

    let propertyQuery = '';
    if (propertyTypes?.length) {
        propertyTypes.forEach(function (item, index) {
            propertyQuery = propertyQuery + `LOWER(PropType) like '%${item.toLowerCase()}%'`;
            if (index < propertyTypes.length - 1) propertyQuery = propertyQuery + ' OR ';
        });
    }

    marketQuery = [marketQuery, propertyQuery].filter((query) => query !== '').join(' AND ');
    return marketQuery;
};

export const getMarketHierarchy = (markets: string[] = [], submarkets: string[] = []) => {
    const submarketMap: Record<string, { market: string; submarket: string }[]> = groupBy(
        submarkets.map((submarket) => JSON.parse(submarket)),
        'market'
    );

    const marketValues = markets.flatMap((market) => {
        const marketSubmarkets = submarketMap[market];
        if (marketSubmarkets) {
            return marketSubmarkets.map((marketSubmarket) => ({
                marketLevel1: market,
                marketLevel2: marketSubmarket.submarket,
            }));
        } else {
            return [{ marketLevel1: market }];
        }
    });

    if (marketValues.length === 0) {
        return;
    }

    return {
        values: marketValues,
    };
};
