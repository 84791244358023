import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { csvLayerApi } from 'api/csvLayerApi';
import { overpassApi } from 'api/overpassApi';
import { searchFiltersApi } from 'api/searchFiltersApi';
import { userApi } from 'api/userApi';
import actionBarSlice from './actionBarSlice';
import appConfigSlice from './appConfigSlice';
import applicationModeSlice from './applicationModeSlice';
import assistantSlice from './assistantSlice';
import { assistantCleanupListenerMiddleware } from './assistantSliceMiddleware';
import basemapSlice from './basemapSlice';
import buildingIdentityReducer from './buildingIdentitySlice';
import buildingsExcludeSettingsSlice from './buildingsExcludeSettingsSlice';
import csvHighlightMatchSlice from './csvHighlightMatchSlice';
import csvLayerSlice from './csvLayerSlice';
import csvLayerToHighlightSetSlice from './csvToHighlightSetSlice';
import daylightWidgetSlice from './daylightWidgetSlice';
import directLineMeasurement3DWidgetSlice from './directLineMeasurement3DWidgetSlice';
import experimentsReducer from './experimentsSlice';
import exportSlice from './exportSlice';
import featuredPresentationsSlice from './featuredPresentationsSlice';
import featuresSlice from './featuresSlice';
import gisUserSlice from './gisUserSlice';
import highlightSetSlice from './highlightSetSlice';
import imageOverlaysSlice from './imageOverlaysSlice';
import layerWindowSlice from './layerWindowSlice';
import leftPanelSlice from './leftPanelSlice';
import LibraryItemActionsSlice from './libraryItemActionsSlice';
import libraryItemSlice from './libraryItemSlice';
import mapDragDropActions from './mapDragDropSlice';
import mapRangeSlice from './mapRangeSlice';
import mapSizeSlice from './mapSizeSlice';
import marketListSlice from './marketListSlice';
import marketSphereOsmMappingSlice from './marketSphereOsmMappingSlice';
import marketViewsSlice from './marketViewsSlice';
import movePinReducer from './movePinSlice';
import pointsSlice from './pointsSlice';
import polygonsLayerSlice from './polygonsLayerSlice';
import presentationShareSlice from './presentationShareSlice';
import presentationSlice from './presentationSlice';
import quickLayerSlice from './quickLayerSlice';
import rightPanelSlice from './rightPanelSlice';
import searchSlice from './searchSlice';
import singlePropertyViewSlice from './singlePropertyViewSlice';
import slideSlice from './slideSlice';
import streetMapViewSlice from './streetMapViewSlice';
import submarketsSlice from './submarketsSlice';
import tableHighlightSetSlice from './tableHighlightSetSlice';
import userSettingsSlice from './userSettingsSlice';
import userSlice from './userSlice';
import weatherWidgetSlice from './weatherWidgetSlice';
import websiteOverlaysSlice from './websiteOverlaysSlice';

export function _createReducerObject() {
    return {
        applicationMode: applicationModeSlice,
        appConfig: appConfigSlice,
        featuredPresentations: featuredPresentationsSlice,
        user: userSlice,
        markets: marketListSlice,
        userSettings: userSettingsSlice,
        gisUser: gisUserSlice,
        singlePropertyView: singlePropertyViewSlice,
        imageOverlayView: imageOverlaysSlice,
        websiteOverlays: websiteOverlaysSlice,
        submarkets: submarketsSlice,
        presentation: presentationSlice,
        libraryItems: libraryItemSlice,
        libraryItemActions: LibraryItemActionsSlice,
        marketViews: marketViewsSlice,
        polygonsLayer: polygonsLayerSlice,
        search: searchSlice,
        highlightSet: highlightSetSlice,
        slide: slideSlice,
        imageOverlays: imageOverlaysSlice,
        basemap: basemapSlice,
        points: pointsSlice,
        mapRangeSlider: mapRangeSlice,
        mapSize: mapSizeSlice,
        streetMapView: streetMapViewSlice,
        daylightWidget: daylightWidgetSlice,
        directLineMeasurement3DWidget: directLineMeasurement3DWidgetSlice,
        weather: weatherWidgetSlice,
        features: featuresSlice,
        layerWindow: layerWindowSlice,
        csvLayer: csvLayerSlice,
        quickLayer: quickLayerSlice,
        dragDrop: mapDragDropActions,
        presentationShare: presentationShareSlice,
        csvLayerToHighlightSet: csvLayerToHighlightSetSlice,
        marketSphereOsmMapping: marketSphereOsmMappingSlice,
        buildingIdentity: buildingIdentityReducer,
        buildingsExcludeSettings: buildingsExcludeSettingsSlice,
        csvHighlightMatch: csvHighlightMatchSlice,
        tableHighlightSet: tableHighlightSetSlice,
        assistant: assistantSlice,
        leftPanel: leftPanelSlice,
        rightPanel: rightPanelSlice,
        actionBar: actionBarSlice,
        movePin: movePinReducer,
        export: exportSlice,
        experiments: experimentsReducer,
        [overpassApi.reducerPath]: overpassApi.reducer,
        [csvLayerApi.reducerPath]: csvLayerApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [searchFiltersApi.reducerPath]: searchFiltersApi.reducer,
    };
}

export const ADDITIONAL_MIDDLEWARE = [
    overpassApi.middleware,
    csvLayerApi.middleware,
    userApi.middleware,
    searchFiltersApi.middleware,
    assistantCleanupListenerMiddleware,
] as const;

export const store = configureStore({
    reducer: _createReducerObject(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(ADDITIONAL_MIDDLEWARE),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    undefined,
    Action<string>
>;
