import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CspCompany } from 'services/graphql/csp';
import { AppDispatch, RootState } from 'store';
import {
    LeftPanelKeys,
    resetLeftPanel,
    selectIsSinglePropertyViewVisible,
    setActiveLeftPanel,
} from './leftPanelSlice';
import { selectOpenPolygonEditor } from './polygonsLayerSlice';
import { clearPresentation } from './presentationSlice';

export interface CustomField {
    id: string;
    title: string;
    value: string;
    hidden: boolean;
    order: number;
}

export interface PropertyEditProps {
    propertyData: {
        propertyType: string;
        propertyStatus: string;
        construction: {
            propertyGrade: string;
            lastRenovationDate: string;
            buildTime: { year: number };
        };
        floors: {
            count: { aboveGround: number };
        };
        dimensions: {
            area: {
                total: {
                    area: {
                        value: number;
                        UoM: string;
                    };
                };
            };
        };
        description: {
            currentOccupancyPercentage: number;
        };
        companies: CspCompany[];
    };
    availabilityData: {
        availabilityStats: {
            fullFloorsAvailable: number;
            directAvailable: string;
            directAvailableRent: string;
            subleaseAvailable: string;
            subleaseAvailableRent: string;
        };
    };
    propertyCustomTenants?: Record<string, unknown>;
    customFields?: CustomField[];
    spvSettings?: { order?: string[]; hiddenItems?: string[] };
    notes?: string;
}

interface SinglePropertyViewState {
    value: {
        propertyId: string;
        activeTabKey: string;
        editPropertyView: boolean;
        propertyEditData: PropertyEditProps;
        addLease: boolean;
        isSpvEditMode: boolean;
    };
}

export const defaultPropertyState: PropertyEditProps = {
    propertyData: {
        propertyType: '',
        propertyStatus: '',
        construction: {
            propertyGrade: '',
            lastRenovationDate: '',
            buildTime: { year: 0 },
        },
        floors: {
            count: { aboveGround: 0 },
        },
        dimensions: {
            area: {
                total: {
                    area: {
                        value: 0,
                        UoM: '',
                    },
                },
            },
        },
        description: {
            currentOccupancyPercentage: 0,
        },
        companies: [] as CspCompany[],
    },
    availabilityData: {
        availabilityStats: {
            fullFloorsAvailable: 0,
            directAvailable: '',
            directAvailableRent: '',
            subleaseAvailable: '',
            subleaseAvailableRent: '',
        },
    },
    propertyCustomTenants: {},
    customFields: [],
    spvSettings: { order: [], hiddenItems: [] },
    notes: '',
};

const initialState: SinglePropertyViewState = {
    value: {
        propertyId: '',
        activeTabKey: 'property',
        editPropertyView: false,
        propertyEditData: defaultPropertyState,
        addLease: false,
        isSpvEditMode: false,
    },
};

export const setSinglePropertyView =
    (visible: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
        const singlePropertyViewVisible = selectIsSinglePropertyViewVisible(getState());
        const isPolygonEditorOpen = selectOpenPolygonEditor(getState());
        if (visible) {
            dispatch(setActiveLeftPanel(LeftPanelKeys.SinglePropertyView));
        } else if (singlePropertyViewVisible && !isPolygonEditorOpen) {
            dispatch(resetLeftPanel());
            dispatch(setEditPropertyView(false));
        }
    };

export const singlePropertyViewSlice = createSlice({
    name: 'singlePropertyView',
    initialState,
    reducers: {
        setSelectedPropertyId(state, action: PayloadAction<string>) {
            state.value.propertyId = action.payload;
        },
        setActiveTabKey(state, action: PayloadAction<string>) {
            state.value.activeTabKey = action.payload;
        },
        setEditPropertyView(state, action: PayloadAction<boolean>) {
            state.value.editPropertyView = action.payload;
        },
        setPropertyEditData(state, action: PayloadAction<PropertyEditProps>) {
            state.value.propertyEditData = action.payload;
        },
        setAddLease(state, action: PayloadAction<boolean>) {
            state.value.addLease = action.payload;
        },
        setIsSpvEditMode(state, action: PayloadAction<boolean>) {
            state.value.isSpvEditMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const {
    setSelectedPropertyId,
    setActiveTabKey,
    setEditPropertyView,
    setPropertyEditData,
    setAddLease,
    setIsSpvEditMode,
} = singlePropertyViewSlice.actions;

export const selectSelectedPropertyId = (state: RootState): string => {
    return state.singlePropertyView?.value?.propertyId;
};

export const selectActiveTabKey = (state: RootState): string => {
    return state.singlePropertyView?.value?.activeTabKey;
};

export const selectEditPropertyView = (state: RootState): boolean => {
    return state.singlePropertyView?.value?.editPropertyView;
};

export const selectPropertyEditData = (state: RootState): PropertyEditProps =>
    state.singlePropertyView?.value?.propertyEditData;

export const selectAddLease = (state: RootState): boolean =>
    state.singlePropertyView?.value?.addLease;

export const selectIsSpvEditMode = (state: RootState): boolean =>
    state.singlePropertyView?.value?.isSpvEditMode;

export default singlePropertyViewSlice.reducer;
