import { ReactNode } from 'react';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import ChatMessage, { ChatMessageQuickAction } from 'components/Chat/ChatMessage';
import { QuickActionType } from 'components/Chat/quickActionType';
import { RootState } from 'store';
import config from 'utils/config';
import { nanoid } from 'utils/idUtils';
import { ActionBarKeys, selectActiveActionBar } from './actionBarSlice';
import { selectActiveRightPanel } from './rightPanelSelectors';
import { RightPanelKeys } from './rightPanelSlice';

export enum OpenAiModels {
    GPT_35_TURBO = 'GPT_35_TURBO',
    GPT_35_TURBO_0125 = 'GPT_35_TURBO_0125',
    GPT_4 = 'GPT_4',
    GPT_4_32K = 'GPT_4_32K',
    GPT_4_TURBO = 'GPT_4_TURBO',
    GPT_4_O = 'GPT_4_O',
    CLAUDE_3_SONNET_35 = 'CLAUDE_3_SONNET_35',
    BAIDU_ERNIE_40 = 'BAIDU_ERNIE_40',
    BAIDU_ERNIE_3_5_128K = 'BAIDU_ERNIE_3_5_128K',
}

interface AssistantPanelState {
    showAssistant: boolean;
    subscriptionKey: string;
    apiUrl: string;
    model: string;
    messages: ChatMessage[];
    loading: boolean;
    thinkingMessage: string;
    currentMessageKey?: string;
    quickActions?: ChatMessageQuickAction[];
    cleanupActions: Partial<Record<QuickActionType, boolean>>;
}

const initialState: AssistantPanelState = {
    showAssistant: false,
    subscriptionKey: config.blackbirdAssistantApiSubscriptionKey,
    apiUrl: config.blackbirdAssistantApiUrl,
    model: OpenAiModels.GPT_4_O,
    messages: [],
    loading: false,
    thinkingMessage: 'Thinking...',
    cleanupActions: {},
};

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {
        createNewMessage: {
            reducer: (state, action: { payload: ChatMessage }) => {
                state.messages.push(action.payload);
                state.currentMessageKey = action.payload.key;
            },
            prepare: (payload: { message: string | ReactNode; role: 'assistant' | 'user' }) => {
                const chatMessage: ChatMessage = {
                    message: payload.message,
                    key: nanoid(),
                    quickActions: [],
                    timestamp: Date.now(),
                    role: payload.role,
                };
                return { payload: chatMessage };
            },
        },

        setThinkingMessage: (state, action: { payload: string }) => {
            state.thinkingMessage = action.payload || 'Thinking...';
        },

        addMessage: (state, action: { payload: ChatMessage }) => {
            state.messages.push(action.payload);
        },

        appendMessage: (state, action: { payload: { key: string; content: string } }) => {
            const message = state.messages.find((message) => message.key === action.payload.key);
            if (message) {
                message.message += action.payload.content;
            }
        },

        appendCurrentMessage: (state, action: { payload: string }) => {
            const message = state.messages.find(
                (message) => message.key === state.currentMessageKey
            );
            if (message) {
                message.message += action.payload;
            }
        },

        clearMessages: (state) => {
            state.messages = [];
            state.loading = false;
        },

        setLoading: (state, action: { payload: boolean }) => {
            state.loading = action.payload;
        },

        addQuickAction: (state, action: { payload: ChatMessageQuickAction }) => {
            const message = state.messages.findLast((message) => message.role === 'assistant');

            if (message) {
                message.quickActions?.push(action.payload);
            }
        },

        // TODO: This is a temporary solution to clear quick actions. Once we add buttons to the chat we can reapply the state instead of clearing it.
        removeQuickAction: (state, action: { payload: QuickActionType }) => {
            const message = state.messages.findLast((message) => message.role === 'assistant');
            if (message) {
                message.quickActions = message.quickActions?.filter(
                    (quickAction) => quickAction.type !== action.payload
                );
            }
        },

        setCurrentMessageKey: (state, action: { payload: string }) => {
            state.currentMessageKey = action.payload;
        },

        addCleanupAction: (state, action: { payload: QuickActionType }) => {
            state.cleanupActions[action.payload] = true;
        },

        removeCleanupAction: (state, action: { payload: QuickActionType }) => {
            delete state.cleanupActions[action.payload];
        },

        clearCleanupActions: (state) => {
            state.cleanupActions = {};
        },
    },
});

export const {
    setThinkingMessage,
    addMessage,
    clearMessages,
    setLoading,
    appendMessage,
    createNewMessage,
    addQuickAction,
    appendCurrentMessage,
    setCurrentMessageKey,
    addCleanupAction,
    removeCleanupAction,
    clearCleanupActions,
    removeQuickAction,
} = assistantSlice.actions;

export const selectSubscriptionKey = (state: RootState) => state.assistant.subscriptionKey;
export const selectApiUrl = (state: RootState) => state.assistant.apiUrl;
export const selectModel = (state: RootState) => state.assistant.model;
export const selectMessages = (state: RootState) => state.assistant.messages;
export const selectLoading = (state: RootState) => state.assistant.loading;
export const selectThinkingMessage = (state: RootState) => state.assistant.thinkingMessage;
export const selectCurrentMessageKey = (state: RootState) => state.assistant.currentMessageKey;
export const selectCleanupActions = (state: RootState) => state.assistant.cleanupActions;
export const selectAssistantVisible = createSelector(
    [selectActiveRightPanel, selectActiveActionBar],
    (activeRightPanel, activeActionBar) => {
        const isAssistantRightPanelVisible = activeRightPanel?.name === RightPanelKeys.Assistant;
        const isAssistantActionBarVisible = activeActionBar?.name === ActionBarKeys.Assistant;

        return isAssistantRightPanelVisible || isAssistantActionBarVisible;
    }
);

export const selectLatestAssistantMessage = createSelector([selectMessages], (messages) =>
    messages.findLast((message) => message.role === 'assistant')
);

export const selectLatestQuickActions = createSelector(
    [selectLatestAssistantMessage],
    (latestAssistantMessage) => latestAssistantMessage?.quickActions
);

export default assistantSlice.reducer;
