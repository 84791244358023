import { createSlice, freeze, PayloadAction } from '@reduxjs/toolkit';
import { useFlags } from 'launchdarkly-react-client-sdk';

import type { ExperimentsStateProperty } from 'components/ExperimentsPanel';
import { useAppSelector } from 'types/hooks';

interface ExperimentsState {
    flags: { [K in ExperimentsStateProperty]?: boolean };
}
const INITIAL_STATE: ExperimentsState = freeze({ flags: {} }, true);

export const experimentsSlice = createSlice({
    name: 'experiments',
    initialState: INITIAL_STATE,
    reducers: {
        setExperimentFlag(
            state,
            { payload }: PayloadAction<{ flagName: ExperimentsStateProperty; value: boolean }>
        ) {
            state.flags[payload.flagName] = payload.value;
        },
    },
});

export function useExperimentFlag(flag: ExperimentsStateProperty): boolean {
    const defaultValue = useFlags()[flag];
    const valueFromState = useAppSelector((state) => state.experiments.flags[flag]);

    return valueFromState ?? defaultValue;
}

export const { setExperimentFlag } = experimentsSlice.actions;

export default experimentsSlice.reducer;
